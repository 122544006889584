import React from "react";
import "./App.css";
import "./EntryText.css";
import { useState, useRef, useEffect } from "react";
import { ListItem } from "./Components/ListItem";

import arrow from "./photos/arrow.svg";
import iconCaret from "./photos/caret.svg";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import { wordStyles } from "./wordStyles";

const fetchString = "https://www.fyl.la:4000/api/";
// const fetchString = "http://192.168.0.231:4000/api/";

export function NewRoom(props) {
  const [roomInput, setRoomInput] = useState("");
  const [start, setStart] = useState(false);
  const [modal, setModal] = useState(false);

  const [messages, setMessages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [messagesLength, setMessagesLength] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(null);
  const [highestIndex, setHighestIndex] = useState(0);

  const [isAnimating, setIsAnimating] = useState(false);

  const [openRecommendation, setOpenRecommendation] = useState(false);

  const [loadingAI, setLoadingAI] = useState(false);
  const [showAIText, setShowAIText] = useState(false);

  const textArea = useRef(null);

  const { t, i18n } = useTranslation();

  const onFocused = (event) => (textArea.current.placeholder = "");

  const onInputChange = (event) => {
    setRoomInput(event.target.value);
  };

  const [numPrompts, setNumPrompts] = useState(0);

  const setCountupValue = () => {
    fetch(fetchString + props.roomName, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setNumPrompts(data["data"][1].length);
      });
  };

  useEffect(() => {
    setCountupValue();
    props.socket.emit("join room", props.roomName);

    // Listen for the 'connect' event which fires upon successfully reconnecting
    props.socket.on("connect", () => {
      // Re-join the room after reconnection
      props.socket.emit("join room", props.roomName);
    });

    // Listen for messages
    props.socket.on("numPrompts", function (msg) {
      // console.log('Received a chat message: ', msg);
      setNumPrompts(msg);
    });
  }, []);

  const add = (value) => {
    if (roomInput !== "") {
      props.socket.emit("chat message", [props.roomName, roomInput]);
      setRoomInput("");
      props.showInfoMessage("", t("added"), true);
    }
    document.getElementById("page").scrollIntoView();
  };

  const BackButton = () => {
    props.setOpenNewRoom(!props.openNewRoom);
    props.socket.emit("leave room", props.roomName);
  };

  const modalButton = () => {
    //Burde legge til at hvis counter er mindre enn lengden, ikke vits å fetche om det er mer i apiet

    fetch(fetchString + props.roomName, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const newPromptsData = data["data"][1];
        const prompts = newPromptsData.map((prompt) => prompt.value);

        var oldMessages = messages;
        let newMessageLength = 0;

        if (prompts.length > messagesLength) {
          for (let i = messagesLength; i < prompts.length; i++) {
            oldMessages.push(prompts[i]);
          }

          oldMessages = oldMessages
            .slice(0, currentIndex + 2)
            .concat(
              oldMessages
                .slice(currentIndex + 2, prompts.length)
                .sort((a, b) => 0.5 - Math.random())
            );

          setMessages(oldMessages);
          setMessagesLength(oldMessages.length);
          newMessageLength = oldMessages.length;
        } else {
          newMessageLength = messagesLength;
        }

        if (start === true && currentIndex <= newMessageLength - 2) {
          setModal(true);
        } else {
          BackButton();
        }
      });
  };

  const keepGoingButton = () => {
    setModal(false);
  };

  const startRound = () => {
    fetch(fetchString + props.roomName, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        var promptsData = data["data"][1];

        if (promptsData.length > 0) {
          promptsData = promptsData.sort((a, b) => 0.5 - Math.random());

          const prompts = promptsData.map((prompt) => prompt.value);

          // TODO: we can here add info about ai or not and use it..
          setMessages(prompts);
          setMessagesLength(prompts.length);

          setStart(true);

          //TODO: for testing period
          if (props.roomName.startsWith("ai ") && prompts.length === 1) {
            setShowAIText(true);
          }
        } else {
          props.showInfoMessage("warning", t("nothing"), false);
        }
      });
  };

  const next = () => {
    if (!isAnimating) {
      //TODO: for testing period
      if (
        props.roomName.startsWith("ai ") &&
        currentIndex + 1 === numPrompts - 1
      ) {
        setShowAIText(true);
      } else {
        setShowAIText(false);
      }

      // If first next or we're so far we've every been, and new stuff has to be added to the room
      // this is there bc when people go back and forward again, we don't need to fetch for new entries
      // nr1 to spare network calls
      // nr2 to avoid the order getting switched when going back
      if (
        (highestIndex === 0 || currentIndex === highestIndex + 1) &&
        numPrompts > messagesLength
      ) {
        // if (highestIndex === 0 || currentIndex === highestIndex + 1) {

        fetch(fetchString + props.roomName, {
          headers: {
            Accept: "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            const newPromptsData = data["data"][1];
            const newPrompts = newPromptsData.map((prompt) => prompt.value);
            var oldMessages = messages;

            // If there are new messages to fetch or we're we haven't gone through all already fetched
            if (
              newPrompts.length > messagesLength ||
              messagesLength > currentIndex + 1
            ) {
              // If there are new messages to fetch
              if (newPrompts.length > messagesLength) {
                for (let i = messagesLength; i < newPrompts.length; i++) {
                  oldMessages.push(newPrompts[i]);
                }

                //TODO: use hooks to auto update
                oldMessages = oldMessages
                  .slice(0, currentIndex + 2)
                  .concat(
                    oldMessages
                      .slice(currentIndex + 2, newPrompts.length)
                      .sort((a, b) => 0.5 - Math.random())
                  );

                setMessages(oldMessages);
                setMessagesLength(oldMessages.length);
              }
              setIsAnimating(true);
              setPreviousIndex(currentIndex);
              setCurrentIndex(currentIndex + 1);

              if (currentIndex > highestIndex) setHighestIndex(currentIndex);

              setTimeout(() => {
                setPreviousIndex(null);
                //setCurrMsg(messages[counter + 1])
                setIsAnimating(false);
              }, 700); // Adjust this value based on your desired animation duration
            } else {
              props.showInfoMessage("warning", t("empty"), false);
            }
          });
      } else {
        if (messagesLength > currentIndex + 1) {
          setIsAnimating(true);
          setPreviousIndex(currentIndex);
          setCurrentIndex(currentIndex + 1);

          if (currentIndex > highestIndex) setHighestIndex(currentIndex);

          setTimeout(() => {
            setPreviousIndex(null);
            //setCurrMsg(messages[counter + 1])
            setIsAnimating(false);
          }, 700); // Adjust this value based on your desired animation duration
        } else {
          if (props.roomName.startsWith("ai ")) {
            add20AIQuestions();
            IncrementalCounter();
          } else {
            props.showInfoMessage("warning", t("empty"), false);
          }
        }
      }
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      //setCurrMsg(messages[counter - 1])
      setCurrentIndex(currentIndex - 1);
      setShowAIText(false);
    }
  };

  function styleWordInText(text, styles) {
    const words = text.split(" ");
    return words.map((word, index) => {
      const lastChar = word.slice(-1);
      const className =
        styles[
          lastChar === "?" ||
          lastChar === "!" ||
          lastChar === ":" ||
          lastChar === "." ||
          lastChar === ";" ||
          lastChar === ","
            ? word.slice(0, -1).toLowerCase()
            : word.toLowerCase()
        ] || "";
      return (
        <span key={index} className={className}>
          {word}
        </span>
      );
    });
  }

  //TODO: FIX
  const divStyle = {
    top: props.containerHeight / 6,
  };

  function entryRender() {
    return (
      <div className="slide-container" style={divStyle}>
        {previousIndex !== null && (
          <div
            className={`${getTextClass(
              messages[previousIndex].length
            )} slide-text ${isAnimating ? "slide-out" : ""}`}
            onAnimationEnd={() => setPreviousIndex(null)}
          >
            {styleWordInText(messages[previousIndex], wordStyles).map(
              (wordElement, index) => (
                <React.Fragment key={index}>
                  {wordElement}
                  {/* Add space if not last word */}
                  {index !== messages[previousIndex].split(" ").length - 1 &&
                    " "}
                </React.Fragment>
              )
            )}
          </div>
        )}
        <div
          className={`${getTextClass(
            messages[currentIndex].length
          )} slide-text ${isAnimating ? "slide-in" : ""}`}
          onAnimationEnd={() => setIsAnimating(false)}
        >
          {styleWordInText(messages[currentIndex], wordStyles).map(
            (wordElement, index) => (
              <React.Fragment key={index}>
                {wordElement}
                {/* Add space if not last word */}
                {index !== messages[currentIndex].split(" ").length - 1 && " "}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    );
  }

  const getTextClass = (messageLength) => {
    if (messageLength < 6) return "entry-text-xxl";
    if (messageLength < 20) return "entry-text-xl";
    if (messageLength < 40) return "entry-text-l";
    if (messageLength < 70) return "entry-text-ll";
    if (messageLength < 130) return "entry-text-m";
    if (messageLength < 200) return "entry-text-s";
    return "entry-text-xs";
  };

  function countUpRender() {
    if (start) {
      return <></>;
    } else {
      if (numPrompts < 100)
        return <div className="countdown-l">{numPrompts}</div>;
      else return <div className="countdown-m">{numPrompts}</div>;
    }
  }

  function countdownRender() {
    const countdownNumber = numPrompts - currentIndex - 1;
    if (countdownNumber < 100)
      return <div className="countdown-l">{countdownNumber}</div>;
    else return <div className="countdown-m">{countdownNumber}</div>;
  }

  function prevButtonRender() {
    if (currentIndex > 0) {
      return (
        <div
          className="button-next-prev button-prev text-color-black shadow-black"
          onClick={prev}
        >
          <img className="button-prev-img" src={arrow} alt="" />
        </div>
      );
    } else {
      return (
        <div
          className="button-next-prev button-prev text-color-black shadow-black box-button-center"
          onClick={prev}
          style={{ visibility: "hidden" }}
        >
          <img className="button-prev-img" src={arrow} alt="" />
        </div>
      );
    }
  }

  function explenationRender() {
    return (
      <div>
        <p className="text-h2 top-margin-m">{t("how-to-play")}</p>
        <p className="top-margin-m">{t("how1")}</p>
        <p className="top-margin-m">{t("how2")}</p>
      </div>
    );
  }

  function recommendation() {
    if (!start) {
      return (
        <div>
          <div
            className="container-row top-margin-m"
            style={{ alignItems: "center" }}
          >
            <div
              className="container-row-start "
              onClick={() => setOpenRecommendation(!openRecommendation)}
            >
              <div className="text-h2">{t("tips")}</div>
              <img
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  rotate: "180deg",
                }}
                src={iconCaret}
                alt=""
                className={
                  openRecommendation
                    ? "rotate top-margin-s"
                    : "rotate-back top-margin-s"
                }
              />
            </div>
          </div>
          {openRecommendation && (
            <div
              style={{
                height: props.containerHeight - 500,
                overflow: "hidden",
                overflowY: "scroll",
              }}
              className={openRecommendation ? "recommendation-slide-in" : ""}
            >
              <ListItem
                recPreview={t("rec-1-preview")}
                recText={t("rec-1-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-2-preview")}
                recText={t("rec-2-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-3-preview")}
                recText={t("rec-3-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-16-preview")}
                recText={t("rec-16-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-4-preview")}
                recText={t("rec-4-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-5-preview")}
                recText={t("rec-5-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-6-preview")}
                recText={t("rec-6-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-7-preview")}
                recText={t("rec-7-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-8-preview")}
                recText={t("rec-8-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-9-preview")}
                recText={t("rec-9-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-10-preview")}
                recText={t("rec-10-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-11-preview")}
                recText={t("rec-11-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-12-preview")}
                recText={t("rec-12-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-13-preview")}
                recText={t("rec-13-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-14-preview")}
                recText={t("rec-14-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-15-preview")}
                recText={t("rec-15-text")}
                setRoomInput={setRoomInput}
              />
              <ListItem
                recPreview={t("rec-17-preview")}
                recText={t("rec-17-text")}
                setRoomInput={setRoomInput}
              />
            </div>
          )}
        </div>
      );
    }
  }

  const isRunningRef = useRef(false);

  async function add20AIQuestions() {
    setLoadingAI(true);
    const promptList = await fetch(fetchString + props.roomName, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        var promptsData = data["data"][1];
        const prompts = promptsData.map((prompt) => prompt.value);

        if (prompts.length > 0) {
          return prompts;
        } else {
        }
      });

    try {
      await fetch(fetchString + "generateai", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ promptList, roomName: props.roomName }),
      });
    } catch (error) {
      console.log(error);
      //TODO: add to language file
      props.showInfoMessage("warning", "Noe gikk galt.", false);
    }

    setLoadingAI(false);
    isRunningRef.current = false;
  }

  async function IncrementalCounter() {
    const steps = 20; // Total steps (increase by 20)

    isRunningRef.current = true; // Start counting

    for (let i = 1; i <= steps; i++) {
      if (!isRunningRef.current) break;

      const progress = i / steps; // 0 to 1
      const delay = Math.log((1 - progress) * 9 + 1) * 300;

      setNumPrompts((prev) => prev + 1);

      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  function modalRender() {
    return (
      <div className="App-header">
        <div>
          <div
            className="button-primary black-fill button-text-l text-color-pink shadow-pink box-button-center"
            onClick={BackButton}
          >
            {t("leave-room")}
          </div>
          <p className="text-h1 top-margin-l">{t("done1")}</p>
          <p>{t("done2")}</p>
        </div>
        <div
          className="button-primary button-text-l text-color-black shadow-black box-button-center"
          onClick={keepGoingButton}
        >
          {t("keep-playing")}
        </div>
      </div>
    );
  }

  function startButtonRender() {
    if (start) {
      return <></>;
    } else {
      return (
        <div
          className="button-primary black-fill button-text-l text-color-pink shadow-pink-opaque box-button-center top-margin-s"
          onClick={startRound}
        >
          {t("start")}
        </div>
      );
    }
  }

  function topBarRender() {
    return (
      <div className="container-row lift-topbar">
        <div className="container-row-start">
          <div
            className="button-tertiary-m button-text-m text-color-black"
            onClick={modalButton}
            style={{ maxHeight: "32.5px" }}
          >
            {t("back")}
          </div>
        </div>
        <div className="container-row">
          <div className="text-h3 text-color-black left-margin-m">ID: </div>
          <div className="text-h3 text-color-black left-margin-s text-force-break">
            {props.roomName}
          </div>
        </div>
      </div>
    );
  }

  function addEntryRender() {
    if (!start) {
      return (
        <div className="container">
          <textarea
            ref={textArea}
            onFocus={onFocused}
            className="input-entry button-primary text-h3 bottom-margin-l"
            type="text"
            autoComplete="off"
            maxLength="280"
            value={roomInput}
            onChange={onInputChange}
            placeholder={t("write-here")}
          ></textarea>
          <div
            className="button-primary button-text-l shadow-black text-color-black box-button-center"
            onClick={add}
          >
            {t("add")}
          </div>
          {/* RUNDT HER SKAL AD VÆRE */}
        </div>
      );
    } else return <></>;
  }

  function nextButtonRender() {
    return (
      <div
        className="button-next-prev text-color-black shadow-black box-button-center next-button"
        onClick={next}
        style={showAIText ? { fontSize: "25px" } : undefined}
      >
        {showAIText ? "FORTSETT MED AI" : t("next")}
      </div>
    );
  }

  function startRoundRender() {
    if (start) {
      return (
        <div className="App-header">
          {countdownRender()}
          {entryRender()}
          {!loadingAI && (
            <div className="prev-next-buttons gap-20">
              {prevButtonRender()}
              {nextButtonRender()}
            </div>
          )}
        </div>
      );
    } else return <></>;
  }

  const renderMainContent = () => (
    <div className="App-header">
      {countUpRender()}
      <div>
        {topBarRender()}
        {startButtonRender()}
        {recommendation()}
      </div>
      {addEntryRender()}
      {startRoundRender()}
    </div>
  );

  const renderContent = () => {
    if (modal) {
      return modalRender();
    }
    return renderMainContent();
  };

  return <div className="App-header">{renderContent()}</div>;
}
