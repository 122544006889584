import React from "react";
import "./App.css";

export function TermsOfSale(props) {
  return (
    <div className="App" style={{ backgroundColor: "#131313", color: "white" }}>
      <header>
        <h1>
          SALGS-
          <br />
          BETINGELSER
        </h1>
      </header>

      <article>
        <section>
          <h1 id="introduction">1. Parter</h1>

          <p>
            Dette er salgsbetingelsene for kjøp av digitale varer på www.fyl.la,
            levert av MAFE Studios DA, et norsk ansvarlig selskap med delt
            ansvar (org.nr. 935 242 045), heretter kalt "oss" eller "vi".
            Kjøperen er deg, brukeren som kjøper en digital vare via tjenesten,
            heretter kalt "deg" eller "brukeren".
          </p>
        </section>

        <section>
          <h1 id="what-do-we-sell">2. Hva selger vi?</h1>

          <p>
            Vi tilbyr én digital vare på Fyl.la: funksjonen “fortsett rom med
            AI”, som lar deg generere videre på rommet på vår plattform. Dette
            er en digital tjeneste som leveres direkte i spillet.
          </p>
        </section>

        <section>
          <h1 id="payment">3. Betaling</h1>

          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>Betaling skjer via Vipps</li>
            <li>Prisen for “fortsett rom med AI” vises tydelig før kjøpet.</li>
            <li>
              Betalingen trekkes én gang når du bekrefter kjøpet. Ingen
              abonnementskostnader eller skjulte gebyrer påløper.
            </li>
          </ul>
        </section>

        <section>
          <h1 id="payment">4. Levering</h1>

          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Den digitale varen (“fortsett rom med AI”) leveres umiddelbart
              etter at betalingen er bekreftet av Vipps.
            </li>
            <li>
              Du får, etter generering, tilgang til det utvidede rommet i
              spillet ditt. Hvis du mister koblingen kan du finne tilbake til
              det med din rom-ID.
            </li>
          </ul>
        </section>

        <section>
          <h1 id="payment">5. Angrerett</h1>

          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Under norsk lov (Angrerettloven) har du normalt 14 dagers
              angrerett på fjernsalg. For digitale varer som leveres umiddelbart
              og ikke på et fysisk medium, som vår tjeneste, kan denne retten
              bortfalle dersom du samtykker til det.
            </li>
            <li>
              Viktig: Ved å kjøpe “fortsett rom med AI” og bekrefte kjøpet,
              samtykker du til umiddelbar levering av den digitale varen og
              godtar at du mister angreretten når leveringen starter. Dette
              skjer når du klikker "Kjøp med vipps“
            </li>
            <li>
              Informasjonen ovenfor vises tydelig på kjøpssiden før du fullfører
              betalingen.
            </li>
          </ul>
        </section>

        <section>
          <h1 id="payment">6. Retur</h1>

          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Siden “fortsett rom med AI” er en digital vare som leveres
              umiddelbart og integreres i spillet ditt, er det ikke mulig å
              returnere den i tradisjonell forstand etter levering.
            </li>
          </ul>
        </section>
      </article>

      <section>
        <h1 id="payment">7. Klager</h1>

        <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
          <li>
            Hvis noe går galt (f.eks. betaling trekkes, men funksjonen ikke
            leveres), kan du kontakte oss på{" "}
            <a href="mailto:fyl.la.contact@gmail.com">
              fyl.la.contact@gmail.com
            </a>
          </li>
          <li>
            Inkluder detaljer om kjøpet ditt (f.eks. rom-ID eller tidspunkt for
            transaksjonen).
          </li>
          <li>
            Vi svarer på henvendelser innen 14 dager og jobber for å løse
            problemet raskt.
          </li>
        </ul>
      </section>

      <section>
        <h1 id="payment">8. Sikkerhet og personopplysninger</h1>

        <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
          <li>
            Betalingsinformasjonen din håndteres av Vipps, og vi lagrer ikke
            kortdetaljer eller lignende selv.
          </li>
          <li>
            For mer informasjon om hvordan vi behandler dataene dine, se vår
            Personvernerklæring.
          </li>
        </ul>
      </section>

      <section>
        <h1 id="payment">9. Konfliktløsning</h1>

        <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
          <li>Disse salgsvilkårene er underlagt norsk lov.</li>
          <li>
            Hvis vi ikke kan løse en tvist direkte, kan du ta kontakt med
            Forbrukertilsynet i Norge for hjelp. Du har også rett til å bringe
            saken inn for norske domstoler.
          </li>
        </ul>
      </section>
      <section>
        <h1 id="payment">10. Kontaktinformasjon</h1>

        <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
          <li>Selger: MAFE Studios DA</li>
          <li>Organisasjonsnummer: 935 242 045</li>
          <li>
            E-post:{" "}
            <a href="mailto:mafestudios.contact@gmail.com">
              mafestudios.contact@gmail.com
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
}
