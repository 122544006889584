import React from "react";
import "./App.css";

export function TermsOfService(props) {
  return (
    <div className="App" style={{ backgroundColor: "#131313", color: "white" }}>
      <header>
        <h1>Bruksvilkår</h1>
      </header>

      <article>
        <section>
          <h1 id="introduction">1. Innledning</h1>

          <p>
            Disse Bruksvilkårene ("Vilkår") regulerer din bruk av nettstedet
            Fyl.la ("Tjenesten"), som drives av MAFE Studios DA ("vi" eller
            "oss"), et norsk Ansvarlig selskap med delt ansvar registrert under
            organisasjonsnummer 935 242 045. Ved å få tilgang til eller bruke
            Tjenesten, godtar du å være bundet av disse Vilkårene. Hvis du ikke
            godtar dem, vennligst ikke bruk Tjenesten.
          </p>
        </section>

        <section>
          <h1 id="what-do-we-sell">2. Bruk av Tjenesten</h1>

          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Formål: Fyl.la er et nettbasert spill der brukere kan opprette
              midlertidige rom og legge inn sitt eget innhold for interaktiv
              spilling. Tjenesten er åpen for brukernes kreativitet og kan
              tilpasses ulike formål.
            </li>
            <li>
              Lovlig Bruk: Du samtykker i å bruke Tjenesten kun til lovlige
              formål og i samsvar med disse Vilkårene.
            </li>
            <li>
              Forbudte Handlinger: Du kan ikke:
              <ul>
                <li>
                  Bruke Tjenesten på en måte som kan skade, deaktivere,
                  overbelaste eller svekke den, eller forstyrre andres bruk.
                </li>
                <li>Forstyrre Tjenestens drift på noen måte.</li>
              </ul>
            </li>
            <li>
              Endringer: Vi kan endre, suspendere eller avvikle Tjenesten når
              som helst uten varsel.
            </li>
          </ul>
        </section>

        <section>
          <h1 id="payment">3. Brukerinnhold</h1>

          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Midlertidig og Anonymt: Innhold du legger inn i Tjenesten (f.eks.
              tekst eller romdata, referert til som "Brukerinnhold") er
              midlertidig og anonymt. Rom og deres innhold slettes etter bruk og
              er kun synlig for brukere i samme rom. Du beholder ikke eierskap
              til noe Brukerinnhold.
            </li>
            <li>
              Forbudt Innhold: Du samtykker i ikke å legge inn Brukerinnhold som
              er:
              <ul>
                <li>Ulovlig, skadelig, eller truende.</li>
                <li>
                  Krenker tredjeparts rettigheter, som immaterielle rettigheter.
                </li>
              </ul>
            </li>
            <li>
              Ansvar: Du er alene ansvarlig for Brukerinnholdet du legger inn.
              Vi kontrollerer, overvåker eller godkjenner ikke Brukerinnhold og
              fraskriver oss alt ansvar knyttet til det.
            </li>
          </ul>
        </section>

        <section>
          <h1 id="payment">4. Immaterielle Rettigheter</h1>

          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Eierskap: Tjenesten, inkludert dens design, funksjoner og
              funksjonalitet, eies av MAFE Studios DA og er beskyttet av norske
              og internasjonale lover om opphavsrett, varemerker og annet.
            </li>
            <li>
              Restriksjoner: Du kan ikke reprodusere, distribuere, modifisere
              eller lage avledede verk av Tjenesten uten vårt skriftlige
              forhåndssamtykke. Du kan ikke kreve eierskap til Tjenestens
              innhold eller design.
            </li>
          </ul>
        </section>

        <section>
          <h1 id="payment">5. Kjøp</h1>

          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Digital Vare: Vi tilbyr én digital vare for kjøp: muligheten til å
              generere videre på et rom i spillet ved hjelp av AI.
            </li>
            <li>
              Betaling: Kjøp behandles via Vipps og belastes ved
              kjøpstidspunktet.
            </li>
            <li>
              Levering: Den digitale varen leveres umiddelbart ved fullført
              betaling.
            </li>
            <li>
              Salgsvilkår: Ved kjøp godtar du våre Salgsvilkår, som presenteres
              ved kjøpstidspunktet.
            </li>
          </ul>
        </section>

        <section>
          <h1 id="payment">6. Ansvarsfraskrivelse og Ansvarsbegrensning</h1>

          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Slik den Er: Tjenesten leveres "som den er" og "som tilgjengelig"
              uten garantier utover det som kreves av norsk lov.
            </li>
            <li>
              Ansvarsbegrensninger: I den grad det er tillatt under norsk lov:
              <ul>
                <li>
                  Vi er ikke ansvarlige for indirekte, tilfeldige, spesielle
                  eller følgeskader fra din bruk av Tjenesten.
                </li>
                <li>
                  Vårt totale ansvar er begrenset til beløpet du betalte for den
                  digitale varen knyttet til kravet.
                </li>
              </ul>
            </li>
            <li>
              Unntak: Vi kan være ansvarlige for direkte skader forårsaket av
              grov uaktsomhet eller forsettlig mislighold, som påkrevd av lov.
            </li>
          </ul>
        </section>
      </article>

      <section>
        <h1 id="payment">7. Erstatning</h1>

        <p>
          Du samtykker i å erstatte og holde MAFE Studios DA, dets partnere og
          ansatte skadesløse fra krav, skader eller utgifter (inkludert rimelige
          advokathonorarer) som oppstår fra:
        </p>

        <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
          <li>Din bruk av Tjenesten.</li>
          <li>Ditt Brukerinnhold.</li>
          <li>Ditt brudd på disse Vilkårene.</li>
        </ul>
      </section>

      <section>
        <h1 id="payment">8. Gjeldende Lov og Tvisteløsning</h1>

        <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
          <li>Gjeldende Lov: Disse Vilkårene er underlagt norsk lov.</li>
          <li>
            Tvister: Eventuelle tvister vil bli løst i norske domstoler. Du kan
            også kontakte Forbrukertilsynet for hjelp.
          </li>
        </ul>
      </section>

      <section>
        <h1 id="payment">9. Endringer i Vilkårene</h1>

        <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
          <li>
            Oppdateringer: Vi kan oppdatere disse Vilkårene og vil publisere
            endringer på Tjenesten.
          </li>
          <li>
            Aksept: Fortsatt bruk av Tjenesten etter oppdateringer betyr at du
            godtar de nye Vilkårene.
          </li>
        </ul>
      </section>
      <section>
        <h1 id="payment">10. Kontakt Oss</h1>

        <p>For spørsmål eller bekymringer, kontakt oss på:</p>

        <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
          <li>
            E-post:{" "}
            <a href="mailto:mafestudios.contact@gmail.com">
              mafestudios.contact@gmail.com
            </a>
          </li>
          <li>MAFE Studios DA</li>
          <li>Org. nr. 935 242 045</li>
        </ul>
      </section>
    </div>
  );
}
