import React from "react";
import "./App.css";

export function PrivacyPolicy(props) {
  return (
    <div className="App" style={{ backgroundColor: "#131313", color: "white" }}>
      <header>
        <h1>
          Personvern-
          <br />
          erklæring
        </h1>
      </header>

      <article>
        <section>
          <p>Sist oppdatert: 25.03.2025</p>
          <p>
            Hos MAFE Studios DA, som driver Fyl.la, er vi opptatt av å beskytte
            personvernet ditt og være åpne om hvordan vi håndterer dataene dine.
            Denne personvernerklæringen forklarer hvilken informasjon vi samler
            inn, hvordan vi bruker den, og hvilke rettigheter du har knyttet til
            dine personopplysninger. Den er i samsvar med EUs
            personvernforordning (GDPR) og den norske personopplysningsloven.
          </p>
        </section>
        <br></br>

        <section>
          <p>
            Kontakt oss: For spørsmål om personvern, vennligst kontakt oss på{" "}
            <a href="mailto:mafestudios.contact@gmail.com">
              mafestudios.contact@gmail.com
            </a>
          </p>
        </section>

        <section>
          <h1 id="introduction">1. Hvem vi er</h1>

          <p>
            MAFE Studios DA, heretter kalt «oss» eller «vi», er
            behandlingsansvarlig for dine personopplysninger. Vi driver Fyl.la,
            et interaktivt spillnettsted der brukere lager og spiller sine egne
            spill.
          </p>
        </section>

        <section>
          <h1 id="what-do-we-sell">2. Hvilke data vi samler inn</h1>

          <p>
            Vi samler inn minimalt med data for å tilby og forbedre tjenestene
            våre: Ikke-personlige data:
          </p>
          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Anonyme innsendinger fra bruker (f.eks. spørsmål eller svar) som
              brukere frivillig legger inn under spilling. Disse er ikke knyttet
              til identifiserbare personer.
            </li>
            <li>
              Bruksdata: Generelle analysedata samlet inn via Google Analytics 4
              (GA4), som sidevisninger, øktvarighet og enhetsinformasjon. Disse
              dataene er aggregert og identifiserer ikke individuelle brukere.
              Vi samler ikke inn personopplysninger som navn, e-postadresser
              eller betalingsinformasjon direkte. All betalingsrelatert data
              håndteres utelukkende av Vipps, og vi lagrer eller behandler ikke
              noe av det.
            </li>
          </ul>
        </section>

        <section>
          <h1 id="payment">3. Hvordan vi bruker dataene dine</h1>

          <p>Vi bruker de innsamlede dataene til følgende formål:</p>
          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Å tilby Fyl.la-spilltjenesten (f.eks. vise innsendte spørsmål i
              under spilling).
            </li>
            <li>
              Å analysere bruksmønstre og forbedre nettstedet ved hjelp av
              Google Analytics 4 (GA4).
            </li>
          </ul>
        </section>

        <section>
          <h1 id="payment">4. Lovlig grunnlag for behandling</h1>

          <p>
            Vi behandler dataene dine basert på følgende lovlige grunnlag under
            GDPR:
          </p>

          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Berettiget interesse: For å tilby og forbedre tjenesten vår,
              inkludert analyse av bruk for å forbedre brukeropplevelsen og
              sikre nettstedets funksjonalitet.
            </li>
            <li>
              Avtale: For å levere spilltjenesten som forespurt av brukerne.
              Siden vi ikke samler inn personopplysninger, er samtykke ikke
              nødvendig. Likevel sørger vi for at datapraksisen vår er
              transparent og i tråd med brukernes forventninger.
            </li>
          </ul>
        </section>

        <section>
          <h1 id="payment">5. Datalagring</h1>

          <p>
            Vi deler ikke dataene dine med tredjeparter, bortsett fra som
            følger:
          </p>

          <ul style={{ listStyleType: "square", fontSize: "1.2rem" }}>
            <li>
              Vipps: For betalingsbehandling av kjøp i spillet. Vipps håndterer
              all betalingsdata direkte, og vi mottar eller lagrer ingen
              personlig betalingsinformasjon.
            </li>
            <li>
              Google Analytics 4 (GA4): For bruksanalyse. GA4 behandler data på
              våre vegne, men identifiserer ikke individuelle brukere.
            </li>
            <li>
              Amazon Web Services (AWS): For hosting av nettstedet og lagring av
              anonyme spillinnspill. AWS fungerer som databehandler under våre
              instruksjoner. Vi kan også dele data med myndigheter dersom det
              kreves av loven.
            </li>
          </ul>
        </section>

        <section>
          <h1 id="payment">6. Dine rettigheter</h1>

          <p>
            Siden vi ikke samler inn personopplysninger, gjelder de fleste
            GDPR-rettigheter (f.eks. innsyn, retting, sletting) ikke. Men hvis
            du tror vi utilsiktet har samlet inn personopplysninger, kan du
            kontakte oss på{" "}
            <a href="mailto:fyl.la.contact@gmail.com">
              fyl.la.contact@gmail.com
            </a>
            , og vi vil behandle forespørselen din umiddelbart.
          </p>
        </section>
      </article>

      <section>
        <h1 id="payment">7. Informasjonskapsler og sporingsteknologier</h1>

        <p>
          Vi bruker Google Analytics 4 (GA4), som kan sette informasjonskapsler
          eller bruke lignende sporingsteknologier for å samle inn bruksdata.
          Disse informasjonskapslene hjelper oss med å forstå hvordan brukere
          samhandler med nettstedet, men de identifiserer ikke enkeltpersoner.
          Administrere informasjonskapsler: Du kan kontrollere innstillingene
          for informasjonskapsler via nettleserinnstillingene dine. Men hvis du
          deaktiverer informasjonskapsler, kan det påvirke opplevelsen din på
          nettstedet.
        </p>
      </section>

      <section>
        <h1 id="payment">8. Endringer i denne erklæringen</h1>

        <p>
          Vi kan oppdatere denne personvernerklæringen fra tid til annen.
          Eventuelle endringer vil bli publisert her med en oppdatert «Sist
          oppdatert»-dato.
        </p>
      </section>

      <section>
        <h1 id="payment">9. Klager</h1>

        <p>
          Hvis du har bekymringer om hvordan vi håndterer dataene dine,
          vennligst kontakt oss på{" "}
          <a href="mailto:fyl.la.contact@gmail.com">fyl.la.contact@gmail.com</a>
          . Du kan også sende inn en klage til Datatilsynet.
        </p>
      </section>
    </div>
  );
}
